// src/app/users/users.service.ts

import { Injectable } from "@angular/core";
import { HttpClient,HttpHeaders } from "@angular/common/http";
import { Observable, throwError} from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { environment } from "src/environments/environment";
import { Programado, ProgramadoAdapter} from "./programado.model";
import { map,catchError,tap } from "rxjs/operators";
import { FullCalendarComponent,CalendarOptions, DateSelectArg, EventClickArg, EventApi, EventInput, CalendarApi, CalendarContent } from '@fullcalendar/angular';
import {
  debounceTime, distinctUntilChanged, switchMap
} from 'rxjs/operators';

@Injectable({
  providedIn: "root"
})

export class ProgramadoService {
    token?: string;
    
    constructor(private http: HttpClient,private cookies: CookieService,private adapter: ProgramadoAdapter) {
        
    }
 
  //Aqui debo agregar la url de nuestro servicio

  insertar(programado: any): Observable<any>{
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    console.log(environment.ipback+"/api/Programado/");
    return this.http.post(environment.ipback+"/api/Programado",programado, {headers : headers});
  }
  modificar(programado: any): Observable<any>{
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    return this.http.put(environment.ipback+"/api/Programado/modificar",programado, {headers : headers});
  }
  getToken() {
    return this.cookies.get("token");
  }

  listado(idZona:any): Observable<any[]> {
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    return this.http.post<any[]>(environment.ipback+"/api/Programado/porZona",idZona,{headers : headers});
  }

  listadoGeneral(idZona:any): Observable<any[]> {
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    return this.http.post<any[]>(environment.ipback+"/api/Programado/listado",idZona,{headers : headers});
  }

  listadoFiltro(filtro :any): Observable<any[]> {
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    return this.http.post<any[]>(environment.ipback+"/api/Programado/fecha/",filtro,{headers : headers});
  }

  unico(id :string): Observable<any[]> {
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    return this.http.get<any[]>(environment.ipback+"/api/Programado/"+id,{headers : headers});
  }

  programadoBusqueda(viaje: any): Observable<any>{
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    console.log(environment.ipback+"/api/Programado/filtrobusqueda");
    return this.http.post(environment.ipback+"/api/Programado/filtrobusqueda",viaje, {headers : headers});
  }
  cambiaEstado(programado: any): Observable<any>{
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    return this.http.put(environment.ipback+"/api/Programado/cambiaEstado",programado, {headers : headers});
  }
private createCompleteRoute = (route: string, envAddress: string) =>    {
  return `${envAddress}/${route}`;
}
errorHandler(error) {

  let errorMessage = '';

  if(error.error instanceof ErrorEvent) {

    errorMessage = error.error.message;

  } else {

    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;

  }

  return throwError(errorMessage);

}
}