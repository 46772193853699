import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';

import {LayoutModule} from './views/layout/layout.module';
import {AuthGuard} from './core/guard/auth.guard';

import {HttpClientModule} from '@angular/common/http';

import {AppComponent} from './app.component';
import {ErrorPageComponent} from './views/pages/error-page/error-page.component';

import {HIGHLIGHT_OPTIONS} from 'ngx-highlightjs';

import {DataTablesModule} from 'angular-datatables';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NgbAlertModule, NgbDatepickerModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ModalComponent} from './core/modal/modal.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {CommonModule, DatePipe, registerLocaleData} from '@angular/common';
import localeEs from '@angular/common/locales/es-CL';
import {DragDropModule} from "@angular/cdk/drag-drop";
import {Ng9RutModule} from "ng9-rut";
import {FeahterIconModule} from "./core/feather-icon/feather-icon.module";

registerLocaleData(localeEs, 'es-CL');

@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    ModalComponent,
  ],
  imports: [
    CommonModule,
    Ng9RutModule,
    BrowserModule,
    DragDropModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    HttpClientModule,
    FormsModule,
    DataTablesModule,
    NgbModule,
    NgSelectModule,
    NgbAlertModule,
    BrowserModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbDatepickerModule,
    FeahterIconModule,
    NgxDatatableModule
  ],
  providers: [

    AuthGuard,
    {
      provide: HIGHLIGHT_OPTIONS, // https://www.npmjs.com/package/ngx-highlightjs
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
        }
      }
    },
    DatePipe,
    {provide: LOCALE_ID, useValue: 'es-CL'},
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
