// src/app/users/users.service.ts

import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {CookieService} from "ngx-cookie-service";
import {environment} from "src/environments/environment";
import {Zona, ZonaAdapter} from "./zona.model";

@Injectable({
  providedIn: "root"
})

export class ZonaService {
  token?: string;

  constructor(private http: HttpClient, private cookies: CookieService, private adapter: ZonaAdapter) {

  }

  //Aqui debo agregar la url de nuestro servicio


  /*register(user: any): Observable<any>{
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    console.log(environment.ipback+"api/Usuario/Registrar");
    return this.http.post(environment.ipback+"/api/Usuario/Registrar",user, {headers : headers});
  }*/


  leer(): Observable<any> {
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    return this.http.get(environment.ipback + "/api/Usuario", {headers: headers});
  }

  setToken(token: string) {
    this.cookies.set("token", token);
  }

  getToken() {
    return this.cookies.get("token");
  }

  listado(): Observable<Zona[]> {
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    return this.http.get<Zona[]>(environment.ipback + "/api/zona", {headers: headers});
  }

  nuevo(nombre: string) {
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    return this.http.post<string>(environment.ipback + "/api/zona", {nombre}, {headers});
  }

  borrar(idZona: number) {
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    return this.http.delete(environment.ipback + `/api/zona/${idZona}`, {headers});
  }

  editar(idZona: number, newNombreZona: string) {
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    return this.http.post(environment.ipback + `/api/zona/editar/`, {nombre: newNombreZona, idZona}, {headers});
  }

  errorHandler(error) {

    let errorMessage = '';

    if (error.error instanceof ErrorEvent) {

      errorMessage = error.error.message;

    } else {

      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;

    }

    return throwError(errorMessage);

  }

  private createCompleteRoute = (route: string, envAddress: string) => {
    return `${envAddress}/${route}`;
  }


}

