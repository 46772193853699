import {Injectable} from "@angular/core";
import {Adapter} from "../../adapter";


export class Viaje {
  constructor(
    public idViaje: string,
    public finProgramado: Date,
    public llegadaCliente: Date,
    public salidaCliente: Date,
    public llegadaDispoFinal: Date,
    public salidaDispoFinal: Date,
    public salidaProgramada: Date,
    public inicio: Date,
    public llegadaBaseProgramada: Date,
    public llegadaBodega: Date,
    public estadoViaje: number,
    public movil: string,
    public equipamiento: string,
    public idChofer: string,
    public chofer: string,
    public servicio: string,
    public idObra: string,
    public obra: string,
    public idBodega: string,
    public bodega: string,
    public idDisposicionFinal: string,
    public disposicionFinal: string,
    public idBaseSalida: string,
    public baseSalida: string,
    public tipoViaje: number,
    public serviciosLink: any[],
    public motivoCierre: string,
    public observaciones: string,
    public fechaCierre: Date,
  ) {
  }

}

@Injectable({
  providedIn: "root",
})
export class ViajeAdapter implements Adapter<Viaje> {
  adapt(item: any): Viaje {
    return new Viaje(
      item.idViaje, item.finProgramado,
      item.llegadaCliente, item.salidaCliente,
      item.llegadaDispoFinal, item.salidaDispoFinal,
      item.salidaProgramada, item.inicio,
      item.llegadaBaseProgramada, item.llegadaBodega,
      item.estadoViaje, item.movil,
      item.equipamiento, item.idChofer, item.chofer,
      item.servicio, item.idObra, item.obra,
      item.idBodega, item.bodega,
      item.idDisposicionFinal, item.disposicionFinal, item.idBaseSalida, item.baseSalida, item.tipoViaje, item.serviciosLink,
      item.motivoCierre, item.observaciones, item.fechaCierre
    );
  }
}
