// src/app/users/users.service.ts

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import {environment} from 'src/environments/environment';
import {Viaje, ViajeAdapter} from './viaje.model';
import {map, catchError, tap} from 'rxjs/operators';
import {
  FullCalendarComponent,
  CalendarOptions,
  DateSelectArg,
  EventClickArg,
  EventApi,
  EventInput,
  CalendarApi,
  CalendarContent
} from '@fullcalendar/angular';
import {
  debounceTime, distinctUntilChanged, switchMap
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ViajeService {
  token?: string;

  constructor(private http: HttpClient, private cookies: CookieService, private adapter: ViajeAdapter) {

  }

  // Aqui debo agregar la url de nuestro servicio

  insertar(viaje: any): Observable<any> {
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    console.log(environment.ipback + '/api/Viaje/');
    console.log(viaje);
    return this.http.post(environment.ipback + '/api/Viaje', viaje, {headers});
  }

  getToken() {
    return this.cookies.get('token');
  }

  viajeBusqueda(viaje: any): Observable<any> {
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    console.log(environment.ipback + '/api/Viaje/filtrobusqueda');
    return this.http.post(environment.ipback + '/api/Viaje/filtrobusqueda', viaje, {headers});
  }

  Actualizar(viaje: any): Observable<any> {
    console.log(viaje);
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    console.log(environment.ipback + '/api/Viaje/');
    return this.http.put(environment.ipback + '/api/Viaje/' + viaje.idViaje, viaje, {headers});
  }

  actualizarTotal(viaje: any): Observable<any> {
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    console.log(environment.ipback + '/api/Viaje/');
    return this.http.put(environment.ipback + '/api/Viaje/cambiarViaje/' + viaje.idViaje, viaje, {headers});
  }

  viajeUnico(viaje: any): Observable<any> {
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    console.log(environment.ipback + '/api/Viaje/');
    return this.http.get(environment.ipback + '/api/Viaje/' + viaje, {headers});
  }

  recepcionDelViaje(viaje: any): Observable<any> {
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    console.log(environment.ipback + '/api/Recepcion/');
    return this.http.get(environment.ipback + '/api/Recepcion/' + viaje, {headers});
  }

  recepcionFinalDelViaje(viaje: any): Observable<any> {
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    console.log(environment.ipback + '/api/RecepcionVertedero/');
    return this.http.get(environment.ipback + '/api/RecepcionVertedero/' + viaje, {headers});
  }

  listado(): Observable<any[]> {
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    return this.http.get<any[]>(environment.ipback + '/api/Viaje', {headers});
  }

  listadoPausados(entrada: any): Observable<any[]> {
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    return this.http.get<any[]>(environment.ipback + '/api/Viaje/pausados', {headers});
  }

  listadoFiltro(busqueda: any): Observable<any[]> {
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    return this.http.post<any[]>(environment.ipback + '/api/Viaje/fecha/', busqueda, {headers});
  }

  listadoViaje(idZona: any): Observable<any[]> {
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    return this.http.post<any[]>(environment.ipback + '/api/Viaje/calendario', idZona, {headers});
  }

  listadoTipoViaje(): Observable<any[]> {
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    return this.http.get<any[]>(environment.ipback + '/api/Viaje/tipoViaje', {headers});
  }

  busquedaEquipamiento(equipamiento: any): Observable<any> {
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    console.log(environment.ipback + '/api/Viaje/busquedaEquipo');
    return this.http.post(environment.ipback + '/api/Viaje/busquedaEquipo', equipamiento, {headers});
  }

  busquedaMovil(equipamiento: any): Observable<any> {
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    console.log(environment.ipback + '/api/Viaje/busquedaMovil');
    return this.http.post(environment.ipback + '/api/Viaje/busquedaMovil', equipamiento, {headers});
  }

  errorHandler(error) {

    let errorMessage = '';

    if (error.error instanceof ErrorEvent) {

      errorMessage = error.error.message;

    } else {

      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;

    }

    return throwError(errorMessage);

  }

  private createCompleteRoute = (route: string, envAddress: string) => {
    return `${envAddress}/${route}`;
  }
}
