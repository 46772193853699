// src/app/users/users.service.ts

import { Injectable } from "@angular/core";
import { HttpClient,HttpHeaders } from "@angular/common/http";
import { Observable, throwError} from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { environment } from "src/environments/environment";
import { UF, UFAdapter} from "./uf.model";
import { map,catchError,tap } from "rxjs/operators";
import { FullCalendarComponent,CalendarOptions, DateSelectArg, EventClickArg, EventApi, EventInput, CalendarApi, CalendarContent } from '@fullcalendar/angular';
import {
  debounceTime, distinctUntilChanged, switchMap
} from 'rxjs/operators';

@Injectable({
  providedIn: "root"
})

export class UFService {
    token?: string;
    
    constructor(private http: HttpClient,private cookies: CookieService,private adapter: UFAdapter) {
        
    }
 
  //Aqui debo agregar la url de nuestro servicio
  
  consultaUFBase(fecha:string): Observable<any>{
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    console.log(environment.ipback+"/api/UF/"+fecha);
    return this.http.get(environment.ipback+"/api/UF/"+fecha, {headers : headers});
  }

  consultaUFRemoto(fecha:string): Observable<any>{
    console.log('https://mindicador.cl/api/uf/'+fecha);
    //console.log(viaje);
    return this.http.get('https://mindicador.cl/api/uf/'+fecha);
  }

  getToken() {
    return this.cookies.get("token");
  }

  InsertarUF(uf: any): Observable<any>{
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    return this.http.post(environment.ipback+"/api/UF/",uf, {headers : headers});
  }

  private createCompleteRoute = (route: string, envAddress: string) =>    {
    return `${envAddress}/${route}`;
  }
errorHandler(error) {

  let errorMessage = '';

  if(error.error instanceof ErrorEvent) {

    errorMessage = error.error.message;

  } else {

    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;

  }

  return throwError(errorMessage);

}
}