import { Injectable } from "@angular/core";
import { Adapter } from "../../adapter";

export class Usuario {
    constructor(
      public email: string,
      public nombreCompleto: string,
      public chofer: string
    ) {}
    
  } 

  @Injectable({
    providedIn: "root",
  })
  export class UsuarioAdapter implements Adapter<Usuario> {
    adapt(item: any): Usuario {
      return new Usuario(item.email, item.nombreCompleto,item.chofer);
    }
  }