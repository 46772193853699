import { Injectable } from "@angular/core";
import { Adapter } from "../../adapter";


export class UF {
    constructor(
     public Fecha:Date,
     public Valor:number
    ) {}
    
  } 

  @Injectable({
    providedIn: "root",
  })
  export class UFAdapter implements Adapter<UF> {
    adapt(item: any): UF {
      return new UF(
        item.Fecha,item.Valor,
      );
    }
  }