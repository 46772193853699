import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BaseComponent} from './views/layout/base/base.component';
import {ErrorPageComponent} from './views/pages/error-page/error-page.component';
import {DecimalPipe} from "@angular/common";


const routes: Routes = [
  {
    path: 'aprobacion',
    loadChildren: () => import('./views/pages/prefactura/prefactura-elements.module').then(m => m.PrefacturaElementsModule)
  },
  {path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule)},
  {
    path: '',
    component: BaseComponent,
    //canActivate: [AuthGuard],
    children: [
      {
        path: 'cliente',
        loadChildren: () => import('./views/pages/clientes/cliente-elements.module').then(m => m.ClienteElementsModule)
      },
      {
        path: 'chofer',
        loadChildren: () => import('./views/pages/choferes/chofer-elements.module').then(m => m.ChoferElementsModule)
      },
      {
        path: 'base',
        loadChildren: () => import('./views/pages/bases/bases-elements.module').then(m => m.BaseElementsModule)
      },
      {
        path: 'obra',
        loadChildren: () => import('./views/pages/obras/obras-elements.module').then(m => m.ObrasElementsModule)
      },
      {
        path: 'vertedero',
        loadChildren: () => import('./views/pages/vertedero/vertedero-elements.module').then(m => m.VertederoElementsModule)
      },
      {
        path: 'usuario',
        loadChildren: () => import('./views/pages/usuarios/usuario-elements.module').then(m => m.UsuarioElementsModule)
      },
      {
        path: 'movil',
        loadChildren: () => import('./views/pages/moviles/movil-elements.module').then(m => m.MovilElementsModule)
      },
      {
        path: 'equipo',
        loadChildren: () => import('./views/pages/equipo/equipo-elements.module').then(m => m.EquipoElementsModule)
      },
      {
        path: 'viajes',
        loadChildren: () => import('./views/pages/viajes/viaje-elements.module').then(m => m.ViajeElementsModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'reportes',
        loadChildren: () => import('./views/pages/reportes/reportes-elements.module').then(m => m.ReporteElementsModule)
      },
      {
        path: 'mantenedor/TipoViaje',
        loadChildren: () => import('./views/pages/mantenedor/tipo-viaje/tipo-viaje.module').then(m => m.TipoViajeModule)
      }
      ,
      {
        path: 'mantenedor',
        loadChildren: () => import('./views/pages/mantenedor/mantenedor.module').then(m => m.MantenedorModule)
      }
      ,
      {
        path: 'cierreMes',
        loadChildren: () => import('./views/pages/cierreMes/cierre-mes.module').then(m => m.CierreMesModule)
      },
      {
        path: 'cancelacion',
        loadChildren: () => import('./views/pages/cancelacion/cancelacion-elements.module').then(m => m.CancelacionElementsModule)
      },
      {
        path: 'prefactura',
        loadChildren: () => import('./views/pages/prefactura/prefactura-elements.module').then(m => m.PrefacturaElementsModule)
      },
      {
        path: 'estadoPago',
        loadChildren: () => import('./views/pages/prefactura/prefactura-elements.module').then(m => m.PrefacturaElementsModule)
      },
      {
        path: 'facturacion',
        loadChildren: () => import('./views/pages/facturacion/facturacion-elements.module').then(m => m.FacturaElementsModule)
      },
      {
        path: 'servicio',
        loadChildren: () => import('./views/pages/servicios/servicios-elements.module').then(m => m.ServicioElementsModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/pages/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'general',
        loadChildren: () => import('./views/pages/general/general.module').then(m => m.GeneralModule)
      },
      {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
      // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    ]
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {
      'type': 404,
      'title': 'Pagina no Encontrada',
      'desc': 'Oopps!! La pagina que buscas no existe'
    }
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent
  },
  {path: '**', redirectTo: 'error', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule],
  providers: [DecimalPipe]
})
export class AppRoutingModule {
}
