import { Injectable } from "@angular/core";
import { Adapter } from "../../adapter";


export class Programado {
    constructor(
      public idProgramado:string,
      public fechaInicioProgramada:Date,
      public fechaTerminoProgramada:Date,
      public diasSemana:string,
      public estadoViaje:number,
      public servicio: string,
      public idObra:string,
      public obra:string,
      public idBodega:string,
      public bodega:string,
      public idDisposicionFinal:string,
      public disposicionFinal:string,
      public idBaseSalida:string,
      public baseSalida:string,
    ) {}
    
  } 

  @Injectable({
    providedIn: "root",
  })
  export class ProgramadoAdapter implements Adapter<Programado> {
    adapt(item: any): Programado {
      return new Programado(
        item.idProgramado,item.fechaInicioProgramada,
        item.fechaTerminoProgramada,item.diasSemana,
        item.estadoViaje,
        item.servicio,item.idObra,item.obra,
        item.idBodega,item.bodega,
        item.idDisposicionFinal,item.disposicionFinal,item.idBaseSalida,item.baseSalida
      );
    }
  }