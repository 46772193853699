import { Injectable } from "@angular/core";
import { Adapter } from "../../adapter";

export class Zona {
    constructor(
      public idZona:string,
      public nombre: string
    ) {}
    
  } 

  @Injectable({
    providedIn: "root",
  })
  export class ZonaAdapter implements Adapter<Zona> {
    adapt(item: any): Zona {
      return new Zona(item.idzona, item.nombre);
    }
  }