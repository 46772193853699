import { Component, OnInit, ViewChild, ElementRef, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { UsersService} from '../../../core/services/users/users.service';
import { date } from 'ngx-custom-validators/src/app/date/validator';
import { stringify } from '@angular/compiler/src/util';
import { ViajeService } from "../../../core/services/viajes/viaje.service";
import { ProgramadoService } from "../../../core/services/programados/programado.service";
import {UFService} from "../../../core/services/uf/uf.service"
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

import { Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { isNull } from '@angular/compiler/src/output/output_ast';

import { ZonaService } from 'src/app/core/services/zonas/zona.service';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  nombrecompleto: string;
  correo: string;
  tempnombre:string;
  tempemail:string;
  hora: Date = new Date();
  hayPorAgendar: Boolean = false;
  rows = [];
  temp = [];
  rowsProgramado = [];
  tempProgramado = [];
  rowsZona=[];
  currentDate: NgbDateStruct;
  hoy:NgbDateStruct;
  toDate:NgbDateStruct;
  cuenta:Number;
  subscription: Subscription;
  statusText: string;
  ValorUF: number;
  zona:number;
  tipoUsuario = 0;
  constructor(
    @Inject(DOCUMENT) private document: Document, 
    private renderer: Renderer2,
    private router: Router,
    private userService: UsersService,
    private programadoService:ProgramadoService,
    private  viajeService: ViajeService,
    private calendar: NgbCalendar,
    private ufService: UFService,
    private zonaService:ZonaService
  ) { 
      setInterval(() =>{
        this.hora = new Date();
      },60000);
    }

  ngOnInit(): void {
    this.traerValores();
    this.currentDate = this.calendar.getToday();
    this.hoy = this.calendar.getToday();
    const now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth()+1;
    let day = new Date(year, month, 0).getDate();
    this.toDate = {year: year, month: month, day: day};
    this.currentDate.day = this.currentDate.day+1;
    this.actualizaListado();
    let Lday = new Date(year, month, +1).getDate();
    var ultimoDia = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    var uDia:NgbDateStruct = {year: ultimoDia.getFullYear(), month: ultimoDia.getMonth()+1, day: ultimoDia.getUTCDate()};
    console.log(ultimoDia);
    console.log(uDia);
    
    this.buscaUF(this.hoy);
    this.buscaUF(uDia);
    //FiN DE MES
    //this.buscaUF(this.toDate);
    //console.log(this.toDate);
    setInterval(() =>{
      this.actualizaListado();
      },300000);
  }

  actualizaListado(){
    var  filtro= {Fecha :this.currentDate.year+"-"+this.currentDate.month+"-"+this.currentDate.day,IdZona:this.tipoUsuario==3 ? this.zona : null}
    this.viajeService.listadoFiltro(filtro).subscribe(      
      data => {
      this.rows = data;
      this.temp = [...data];
      //console.log(data);
    },
    (error)=>{console.log(error)
    },
    
    ()=>{
      this.programadoService.listadoFiltro(filtro).subscribe(
        (data) => {
                    this.rowsProgramado = data;
          this.tempProgramado = [...data];
          //console.log(data);
        },
        (error) => { 
          console.log(error);
          localStorage.removeItem('isLoggedin');
          this.router.navigate(['/auth/login']);
         },
        () => { 
          //console.log(`listado completed`); 
          this.rowsProgramado.forEach(element => {
            this.rows.forEach(agendado =>{
              if(element.id == agendado.idProgramado){
                //console.log("HAY1");
                //console.log(element);
                this.tempProgramado = this.tempProgramado.filter(x=>x.id != agendado.idProgramado);
              }
            });
          });
          this.rowsProgramado = this.tempProgramado;
          this.cuenta = this.rowsProgramado.length;
          if(this.cuenta>0){
            this.hayPorAgendar=true;
          }
        });
    });
  }


  buscaUF(date:NgbDateStruct){
    this.ufService.consultaUFBase(date.year+"-"+date.month+"-"+date.day).subscribe(
      data => {
        console.log(data);
        if(data == null){
          this.guardaUF(date);
        }else{
          this.ValorUF = data.valor;
        }
    },
    (error)=>{
      console.log(error);
      this.guardaUF(date);
    },
    
    ()=>{

    });
  }
  guardaUF(date:NgbDateStruct){
    this.ufService.consultaUFRemoto(date.day+"-"+date.month+"-"+date.year).subscribe(
      data2 => {
        console.log(data2);
        console.log(data2.serie[0].valor);
        this.ValorUF= data2.serie[0].valor;
        console.log(this.ValorUF);
      },
      (error)=>{
        console.log(error);
      },
      ()=>{
        var fecha = date.year+"-"+date.month+"-"+date.day;
        this.ufService.InsertarUF({fecha: fecha,valor: this.ValorUF}).subscribe(
          data3=>{

          },
          (error)=>{
            console.log(error);
          },
          ()=>{
            console.info("Se cargo Correctamente la UF del día");
          }
        );
      }
    )
  }
  traerValores(){
    /*this.userService.leer().subscribe( data => {
      console.log("guardook");
      this.nombrecompleto = data.nombreCompleto;
      this.correo = data.email;
    });*/
  
    this.userService.leer().subscribe(
      data => {
        //console.log("trajo datos");
        this.tempnombre = data.nombreCompleto;
        this.tempemail = data.email;
        localStorage.setItem('usuario', this.tempemail);
        this.tipoUsuario=data.idPerfil;
        this.zona = data.idZona;      
      }
      ,
      error => {
        console.log('Http Error',error);
        localStorage.removeItem('isLoggedin');
        this.router.navigate(['/auth/login']);
      },
      () => { 
        this.nombrecompleto = this.tempnombre;
        this.correo = this.tempemail;
        //console.log('Http request complete');
    }
    );
  }
  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout(e) {
    e.preventDefault();
    localStorage.removeItem('isLoggedin');

    if (!localStorage.getItem('isLoggedin')) {
      this.router.navigate(['/auth/login']);
    }
  }

}
