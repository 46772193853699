// src/app/users/users.service.ts

import { Injectable } from "@angular/core";
import { HttpClient,HttpHeaders } from "@angular/common/http";
import { Observable, throwError} from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { environment } from "src/environments/environment";
import { Usuario, UsuarioAdapter } from "./usuario.model";
import { map,catchError,tap } from "rxjs/operators";
import {
  debounceTime, distinctUntilChanged, switchMap
} from 'rxjs/operators';

@Injectable({
  providedIn: "root"
})

export class UsersService {
    token?: string;
    
    constructor(private http: HttpClient,private cookies: CookieService,private adapter: UsuarioAdapter) {
        
    }
 
  //Aqui debo agregar la url de nuestro servicio
  
  login(user: any): Observable<any> {
    this.token = this.getToken();
    return this.http.post( environment.ipback+"/api/Usuario/Login", user);
  }
  editar(id:string,user: any): Observable<any> {
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    return this.http.put( environment.ipback+"/api/Usuario/"+id, user, {headers : headers});
  }

  register(user: any): Observable<any>{
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    console.log(environment.ipback+"api/Usuario/Registrar");
    return this.http.post(environment.ipback+"/api/Usuario/Registrar",user, {headers : headers});
  }
  leer(): Observable<any>{
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    return this.http.get(environment.ipback+"/api/Usuario",{headers : headers});
  }
  traeUnico():Observable<any>{
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    return this.http.get(environment.ipback+"/api/Usuario",{headers : headers});
  }
  leerPerfiles(): Observable<any>{
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    return this.http.get(environment.ipback+"/api/Usuario/perfiles",{headers : headers});
  }
  setToken(token: string) {
    this.cookies.set("token", token);
  }
  getToken() {
    return this.cookies.get("token");
  }

  list(): Observable<Usuario[]> {
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    return this.http.get(environment.ipback+"/api/Usuario/listado",{headers : headers}).pipe(
      // Adapt each item in the raw data array
      map((data: any[]) => data.map((item) => this.adapter.adapt(item)))
      //map(res => res.json())
    );
  }
  listado(): Observable<Usuario[]> {
    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    return this.http.get<Usuario[]>(environment.ipback+"/api/Usuario/listado",{headers : headers});
  }

  getAll(): Observable<Usuario[]> {

    this.token = this.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    //return this.http.get<Usuario[]>(environment.ipback+"/api/Usuario/listado",{headers : headers});    
    return this.http.get<Usuario[]>(environment.ipback+"/api/Usuario/listado",{headers : headers})
    .pipe(

      catchError(this.errorHandler)

    )

  }
private createCompleteRoute = (route: string, envAddress: string) =>    {
  return `${envAddress}/${route}`;
}
errorHandler(error) {

  let errorMessage = '';

  if(error.error instanceof ErrorEvent) {

    errorMessage = error.error.message;

  } else {

    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;

  }

  return throwError(errorMessage);

}
}

